import request from "./http.js";
// 登录
export const adminlogin = (data) => {
  // let data = JSON.stringify(param)
  return request({
    url: "/backend/adminlogin/dologin",
    method: "post",
    data,
  });
};

// 退出登录
export const loginout = () => {
  return request({
    url: "/backend/adminlogin/login_out",
    method: "post",
  });
};

// 图片上传
export const updataImg = (data, type) => {
  let fromdata = new FormData();
  fromdata.append("image", data);
  console.log(data);
  fromdata.append("type", type);
  return request({
    url: "/backend/uploadsfile/upload_image",
    method: "post",
    data: fromdata,
  });
};

// xls文件上传
export const updataxls = (data) => {
  return request({
    url: "/backend/store/store_import",
    method: "post",
    data,
  });
};

// 导出文件
export const exportData = (data) => {
  return request({
    url: "/backend/store/outExcel",
    method: "post",
    data,
  });
};

// 获取地区
export const getarea = (data) => {
  return request({
    url: "/backend/store/get_area",
    method: "post",
    data,
  });
};

// 商品列表
export const getgoodslist = (data, type) => {
  if (type) {
    return request({
      method: "get",
      url: "/backend/goods/list_goods",
      responseType: type,
      headers: { "Content-Type": "form-data;charset=UTF-8" },
      params: data,
    });
  } else {
    return request({
      method: "get",
      url: "/backend/goods/list_goods",
      params: data,
    });
  }
};

// 添加门店
export const redactShop = (data) => {
  return request({
    method: "post",
    url: "/backend/store/edit_or_sub_store",
    data,
  });
};

// 获取门店详情
export const getshopdetail = (data) => {
  return request({
    url: "/backend/store/get_store_info",
    method: "post",
    data,
  });
};

// 更改门店状态
export const setShopStatus = (data) => {
  return request({
    url: "/backend/store/store_status",
    method: "post",
    data,
  });
};

// 删除门店
export const delShop = (data) => {
  return request({
    url: "/backend/store/del_store",
    method: "post",
    data,
  });
};

// 获取商品分类列表
export const shopclass = (data) => {
  return request({
    url: "/backend/cat/list_cat",
    method: "get",
    params: data,
  });
};

// 添加编辑商品分类项
export const addshopclass = (data) => {
  return request({
    url: "/backend/cat/add_cat",
    method: "post",
    data,
  });
};

// 添加商品
export const addgoods = (data) => {
  return request({
    url: "/backend/goods/add_goods",
    method: "post",
    data,
  });
};

// 删除商品
export const delgoods = (data) => {
  return request({
    url: "/backend/goods/delete_goods",
    method: "post",
    data,
  });
};

// 商品详情编辑
export const goodsdetail = (data) => {
  return request({
    url: "/backend/goods/detail_goods",
    method: "post",
    data,
  });
};

// 商品分类详情
export const catdetail = (data) => {
  return request({
    url: "/backend/cat/detail_cat",
    method: "post",
    data,
  });
};

// 获取所有分类
export const getcatAll = () => {
  return request({
    url: "/backend/cat/get_all_cat",
    method: "get",
  });
};

// 删除商品分类
export const delgoods_cat = (data) => {
  return request({
    url: "/backend/cat/delete_cat",
    method: "post",
    data,
  });
};

// 商品上下架
export const setGoodsStatus = (data) => {
  return request({
    url: "/backend/goods/change_status",
    method: "post",
    data,
  });
};

// 获取门店用户列表
export const getshopuser = (data) => {
  return request({
    url: "/backend/storeuser/get_alluser_list",
    method: "post",
    data,
  });
};

// 获取门店会员
export const getshopVip = (data) => {
  return request({
    url: "/backend/storeuser/store_vipuser",
    method: "post",
    data,
  });
};

// 获取门店访客列表
export const shopuserlist = (data) => {
  return request({
    url: "/backend/storeuser/get_storeuser_list",
    method: "post",
    data,
  });
};

// 修改用户状态
export const setUserStatus = (data) => {
  return request({
    url: "/backend/storeuser/user_status",
    method: "post",
    data,
  });
};

// 用户升级会员
export const upVIP = (data) => {
  return request({
    url: "/backend/storeuser/upvip",
    method: "post",
    data,
  });
};

// 删除用户
export const deluser = (data) => {
  return request({
    url: "/backend/storeuser/del_storeuser",
    method: "post",
    data,
  });
};

// 变更用户角色
export const setAdminRole = (data) => {
  return request({
    url: "/backend/storeuser/edit_storerole",
    method: "post",
    data,
  });
};

// 门店角色列表
export const rolelist = (data) => {
  return request({
    url: "/backend/storerole/get_storerole_list",
    method: "post",
    data,
  });
};

// 删除角色
export const delrole = (data) => {
  return request({
    url: "/backend/storerole/del_storerole",
    method: "post",
    data,
  });
};

// 添加角色
export const addrole = (data) => {
  return request({
    url: "/backend/storerole/edit_or_sub_store",
    method: "post",
    data,
  });
};

// 修改角色状态
export const setRolestatus = (data) => {
  return request({
    url: "/backend/storerole/storerole_status",
    method: "post",
    data,
  });
};
// 门店列表
export const getshoplist = (data, type) => {
  return request({
    url: "/backend/store/get_store_list",
    method: "post",
    responseType: type,
    headers: { "Content-Type": "form-data;charset=UTF-8" },
    data,
  });
};
// 获取订单列表
export const getorderlist = (data, type) => {
  return request({
    url: "/backend/order/list_order",
    method: "post",
    responseType: type,
    headers: { "Content-Type": "form-data;charset=UTF-8" },
    data,
  });
};

// 售后订单
export const refundOrderList = (data, type) => {
  return request({
    url: "/backend/orderrefund/list_order_refund",
    method: "get",
    responseType: type,
    headers: { "Content-Type": "form-data;charset=UTF-8" },
    params: data,
  });
};

// 查看订单
export const lookOrder = (data) => {
  return request({
    url: "/backend/order/detail_order",
    params: data,
  });
};

// 门店提现记录
export const shopCapital = (data, type) => {
  return request({
    url: "/backend/storecapitallog/list_store_capital",
    method: "get",
	responseType: type,
	headers: { "Content-Type": "form-data;charset=UTF-8" },
    params: data,
  });
};

// 门店提现明细
export const txOrderDetail = function (data, type) {
  if(type){
    return request({
      url: '/backend/storecapitallog/tx_order_detail',
      method: 'post',
      responseType: type,
      headers: { "Content-Type": "form-data;charset=UTF-8" },
      data
    })
  }else{
    return request({
      url: '/backend/storecapitallog/tx_order_detail',
      method: 'post',
      data
    })
  }
}


// 门店提现审核
export const shopCheck = (data) => {
  return request({
    url: "/backend/storecapitallog/apply_store_capital",
    method: "post",
    data,
  });
};

//门店提现汇款
export const shopRemit = (data) => {
  return request({
    url: "/backend/storecapitallog/go_pay",
    method: "post",
    data,
  });
};

// 用户提现记录
export const userCapital = (data) => {
  return request({
    url: "/backend/usercapitallog/list_user_capital",
    method: "get",
    params: data,
  });
};

// 用户提现审核
export const userCheck = (data) => {
  return request({
    url: "/backend/usercapitallog/apply_user_capital",
    method: "post",
    data,
  });
};

// 用户提现汇款
export const userRemit = (data) => {
  return request({
    url: "/backend/usercapitallog/go_pay",
    method: "post",
    data,
  });
};

// 添加门店时的门店列表
export const shopTypeListAll = (data) => {
  return request({
    url: "/backend/store/get_storetype",
    method: "post",
    data,
  });
};

// 门店类型列表
export const shoptypelist = (data) => {
  return request({
    url: "/backend/storetype/get_storetype_list",
    method: "post",
    data,
  });
};

// 添加门店分类
export const addShopType = (data) => {
  return request({
    url: "/backend/storetype/edit_or_sub_storetype",
    method: "post",
    data,
  });
};

// 删除门店分类
export const delShopType = (data) => {
  return request({
    url: "/backend/storetype/del_storetype",
    method: "post",
    data,
  });
};

// 门店类型详情
export const shopTypeDetail = (data) => {
  return request({
    url: "/backend/storetype/get_storetype_info",
    method: "post",
    data,
  });
};

// 修改门店类型状态
export const shopTypeStatus = (data) => {
  return request({
    url: "/backend/storetype/storetype_status",
    method: "post",
    data,
  });
};

// 预警订单列表
export const warningOrder = (data, type) => {
  if (type) {
    return request({
      url: "/backend/orderrefund/warning_order_refund",
      method: "post",
      responseType: type,
      headers: { "Content-Type": "form-data;charset=UTF-8" },
      data,
    });
  } else {
    return request({
      url: "/backend/orderrefund/warning_order_refund",
      method: "post",
      data,
    });
  }
};

// 门店生成分享二维码
export const scStoreQrcode = (data) => {
  return request({
    url: "/backend/store/qrcode",
    method: "post",
    data,
  });
};

// 审核订单退款
export const applyOrderRefund = (data) => {
  return request({
    url: "/backend/orderrefund/apply_order_refund",
    method: "post",
    data,
  });
};

// 会员换绑门店
export const bindVipStore = (data) => {
  return request({
    url: "/backend/storeuser/band_store",
    method: "post",
    data,
  });
};

// 获取直播分类
export const findLiveCate = (data) => {
  return request({
    url: "/backend/live/live_cate_list",
    method: "post",
    data,
  });
};

// 添加直播分类
export const addLiveCate = (data) => {
  return request({
    url: "/backend/live/save_live_cate",
    method: "post",
    data,
  });
};

// 获取直播分类详情
export const findLiveCateDetail = (data) => {
  return request({
    url: "/backend/live/live_cate_detail",
    method: "post",
    data,
  });
};

// 删除直播分类
export const delLiveCate = (data) => {
  return request({
    url: "/backend/live/del_livecate",
    method: "post",
    data,
  });
};

// 创建直播间时获取直播分类
export const findLiveCateByLiveroom = (data) => {
  return request({
    url: "/backend/live/live_cate",
    method: "post",
    data,
  });
};

// 创建直播间时获取直播人员
export const findLivePeopleByLiveroom = (data) => {
  return request({
    url: "/backend/live/get_live_user",
    method: "post",
    data,
  });
};

// 创建直播间时获取直播商品列表
export const findLiveProductByLiveroom = (data) => {
  return request({
    url: "/backend/live/choice_goods",
    method: "post",
    data,
  });
};

// 创建直播间提交表单
export const submitCreateLiveRoom = (data) => {
  return request({
    url: "/backend/live/save_live",
    method: "post",
    data,
  });
};

// 获取直播间列表
export const findLiveRoomList = (data) => {
  return request({
    url: "/backend/live/live_list",
    method: "post",
    data,
  });
};

// 获取直播间详情
export const findLiveRoomDetail = (data) => {
  return request({
    url: "/backend/live/live_detail",
    method: "post",
    data,
  });
};

// 删除直播间
export const deleteLiveRoom = (data) => {
  return request({
    url: "/backend/live/del_live",
    method: "post",
    data,
  });
};

// 关闭直播间
export const closeLiveRoom = (data) => {
  return request({
    url: "/backend/live/close_live",
    method: "post",
    data,
  });
};

// 直播间中奖列表
export const luckdrawsList = (data) => {
  return request({
    url: "/backend/luckdraws/list",
    method: "get",
    params: data,
  });
};


// 直播间中奖信息上传 /backend/luckdraws/save
export const luckdrawsSave = (data) => {
  return request({
    url: "/backend/luckdraws/save",
    method: "post",
    data,
  });
};

// 直播间中奖人员列表 /backend/luckdrawsaddress/list
export const luckdrawsaddressList = (data) => {
  return request({
    url: "/backend/luckdrawsaddress/list",
    method: "get",
    params: data,
  });
};


// 直播间中奖人员导入 /backend/luckdraws/import
export const luckdrawsImport = (data) => {
  return request({
    url: "/backend/luckdraws/import",
    method: "post",
    data,
  });
};

// 抽奖信息展示  /backend/luckdraws/change
export const luckdrawsChange = (data) => {
  return request({
    url: "/backend/luckdraws/change",
    method: "post",
    data,
  });
};


// 抽奖信息详情  /backend/luckdraws/details
export const luckdrawsDetails = (data) => {
  return request({
    url: "/backend/luckdraws/details",
    method: "get",
    params: data,
  });
};

// 抽奖信息详情  backend/luckdraws/delete
export const luckdrawsDelete = (data) => {
  return request({
    url: "backend/luckdraws/delete",
    method: "get",
    params: data,
  });
};

// 直播间中奖人员列表导出 /backend/luckdrawsaddress/outexcel
export const luckdrawsaddressOutexcel = (data, type) => {
  return request({
    url: "/backend/luckdrawsaddress/outexcel",
    method: "post",
    responseType: type,
    headers: { "Content-Type": "form-data;charset=UTF-8" },
    data,
  });
  // return request({
  //   url: "/backend/luckdrawsaddress/outexcel",
  //   method: "get",
  //   params: data,
  // });
};

// 评论列表
export const get_comment_list = (data) => {
  return request({
    url: "/backend/comment/get_comment_list",
    method: "post",
    data,
  });
};

// 评论列表 是否显示
export const hide_comment = (data) => {
  return request({
    url: "/backend/comment/hide_comment",
    method: "post",
    data,
  });
};

// 删除评论
export const del_comment = (data) => {
  return request({
    url: "/backend/comment/del_comment",
    method: "post",
    data,
  });
};

// 后台给商品添加假评论
export const addFalseComment = (data) => {
  return request({
    url: "/backend/comment/goods_connent",
    method: "post",
    data,
  });
};

// 添加/编辑新闻分类
export const addNewsCategory = (data) => {
  return request({
    url: "/backend/Newsclassify/classifySave",
    method: "post",
    data,
  });
};

// 获取所有新闻分类
export const findAllNewsCategory = (data) => {
  return request({
    url: "/backend/Newsclassify/getAllClassify",
    method: "post",
    data,
  });
};

// 获取新闻分类详情
export const findNewsCateDetail = (data) => {
  return request({
    url: "/backend/Newsclassify/classifyDetails",
    method: "post",
    data,
  });
};

// 删除新闻分类
export const delNewsCate = (data) => {
  return request({
    url: "/backend/Newsclassify/classifyDelete",
    method: "post",
    data,
  });
};

//资讯列表
export const NewsList = (data) => {
  return request({
    url: "/backend/News/NewsList",
    method: "post",
    data,
  });
};

//添加编辑资讯列表
export const newsSave = (data) => {
  return request({
    url: "/backend/news/NewsSave",
    method: "post",
    data,
  });
};

//资讯详情
export const CourseDetails = (data) => {
  return request({
    url: "/backend/News/NewsDetails",
    method: "post",
    data,
  });
};

//删除资讯
export const newsDelete = (data) => {
  return request({
    url: "/backend/News/NewsDelete",
    method: "post",
    data,
  });
};

// 审核资讯
export const newsChange = (data) => {
  return request({
    url: "/backend/News/newsChange",
    method: "post",
    data,
  });
};

// 资讯关联商品列表
export const findNewsChoiceGoods = (data) => {
  return request({
    url: "/backend/news/choice_goods",
    method: "post",
    data,
  });
};

// 获取轮播图列表
export const getNewsBannerList = (data) => {
  return request({
    url: "/backend/newsrotation/list",
    method: "post",
    data,
  });
};

// 修改轮播图状态
export const setNewsBannerStatus = (data) => {
  return request({
    url: "/backend/newsrotation/change",
    method: "post",
    data,
  });
};

// 添加/编辑轮播图
export const addNewsBanner = (data) => {
  return request({
    url: "/backend/newsrotation/save",
    method: "post",
    data,
  });
};

// 删除轮播图
export const delNewsBanner = (data) => {
  return request({
    url: "/backend/newsrotation/delete",
    method: "post",
    data,
  });
};

// 轮播图详情
export const getNewsBannerDetails = (data) => {
  return request({
    url: "/backend/newsrotation/details",
    method: "post",
    data,
  });
};

//课程分类列表
export const classifyList = (data) => {
  return request({
    url: "/backend/Courseclassify/classifyList",
    method: "post",
    data,
  });
};

//添加编辑课程分类
export const classifyAdd = (data) => {
  return request({
    url: "/backend/Courseclassify/classifySave",
    method: "post",
    data,
  });
};

// 课程分类详情
export const classifyDetails = (data) => {
  return request({
    url: "/backend/Courseclassify/classifyDetails",
    method: "post",
    data,
  });
};

//编辑课程分类
export const classifyEdit = (data) => {
  return request({
    url: "/backend/Courseclassify/classifyEdit",
    method: "post",
    data,
  });
};

//删除课程分类
export const classifyDelete = (data) => {
  return request({
    url: "/backend/Courseclassify/classifyDelete",
    method: "post",
    data,
  });
};

//课程列表
export const CourseList = (data) => {
  return request({
    url: "/backend/Course/CourseList",
    method: "post",
    data,
  });
};

//添加课程
export const CourseAdd = (data) => {
  return request({
    url: "/backend/Course/CourseSave",
    method: "post",
    data,
  });
};

// 课程详情
export const CourseDetailss = (data) => {
  return request({
    url: "/backend/Course/CourseDetails",
    method: "post",
    data,
  });
};

// 删除课程
export const CourseDelete = (data) => {
  return request({
    url: "/backend/Course/CourseDelete",
    method: "post",
    data,
  });
};

//课程内容列表
export const contentList = (data) => {
  return request({
    url: "/backend/Course/contentList",
    method: "post",
    data,
  });
};

// 添加编辑内容
export const content_Save = (data) => {
  return request({
    url: "/backend/Course/contentSave",
    method: "post",
    data,
  });
};

//删除内容
export const contentDelete = (data) => {
  return request({
    url: "/backend/Course/contentDelete",
    method: "post",
    data,
  });
};

// 审核
export const newsCheck = (data) => {
  return request({
    url: "/backend/News/newsCheck",
    method: "post",
    data,
  });
};

// 导出
export const outExcel = (data) => {
  return request({
    url: "/backend/store/outExcel",
    method: "post",
    responseType: "blob",
    headers: { "Content-Type": "text/html;charset=UTF-8" },

    data,
  });
};
export const chapterSave = (data) => {
  return request({
    url: "/backend/Course/chapterSave",
    method: "post",
    data,
  });
};

//章节列表
export const chapterList = (data) => {
  return request({
    url: "/backend/Course/chapterList",
    method: "post",
    data,
  });
};

// 删除章节
export const chapterDelete = (data) => {
  return request({
    url: "/backend/Course/chapterDelete",
    method: "post",
    data,
  });
};

// 添加虚拟用户
export const addVirtualUser = (data) => {
  return request({
    url: "/backend/Virtual/virUserSave",
    method: "post",
    data,
  });
};

// 获取虚拟用户列表
export const findVirtualUserList = (data) => {
  return request({
    url: "/backend/Virtual/virUserList",
    method: "post",
    data,
  });
};

// 删除虚拟用户列表
export const delVirtualUserList = (data) => {
  return request({
    url: "/backend/Virtual/virUserDel",
    method: "post",
    data,
  });
};
export const getSystemsetting = (data) => {
  return request({
    url: "/backend/systemsettings/getSystemsettingInfo",
    method: "post",
    data,
  });
};

//首页图表信息
export const index = (data) => {
  return request({
    url: "/backend/Index/index",
    method: "post",
    data,
  });
};

//设置主播
export const setLiver = (data) => {
  return request({
    url: "/backend/storeuser/setLiver",
    method: "post",
    data,
  });
};

//获取门店成员
export const getStoreUser = (data) => {
  return request({
    url: "/backend/storeuser/getStoreUser",
    method: "post",
    data,
  });
};

//直播记录列表、
export const getLivePlaybackList = (data) => {
  return request({
    url: "/backend/live/getLivePlaybackList",
    method: "post",
    data,
  });
};

//获取群成员列表
export const getGroupMemberList = (data) => {
  return request({
    url: "/backend/live/getGroupMemberList",
    method: "post",
    data,
  });
};

//设置禁言
export const modifyGroupMemberInfo = (data) => {
  return request({
    url: "/backend/live/modifyGroupMemberInfo",
    method: "post",
    data,
  });
};

//获取系统设置
export const get_systemsetting = (data) => {
  return request({
    url: "/backend/systemsettings/getSystemsetting",
    method: "post",
    data,
  });
};
export const saveSystemsetting = (data) => {
  return request({
    url: "/backend/systemsettings/saveSystemsettings",
    method: "post",
    data,
  });
};

// 获取系统设置分类
export const getSystemsettingType = (data) => {
  return request({
    url: "/backend/systemsettings/getSystemsettingType",
    method: "post",
    data,
  });
};

// 优惠券列表
export const couponList = (data) => {
  return request({
    url: "/backend/coupon/couponList",
    method: "post",
    data,
  });
};

// 获取分类
export const getCatGoods = (data) => {
  return request({
    url: "/backend/coupon/getCatGoods",
    method: "post",
    data,
  });
};

// 获取商品
export const getGoods = (data) => {
  return request({
    url: "/backend/coupon/getGoods",
    method: "post",
    data,
  });
};

// 添加优惠券
export const saveCoupon = (data) => {
  return request({
    url: "/backend/coupon/saveCoupon",
    method: "post",
    data,
  });
};

// 删除优惠券
export const delCoupon = (data) => {
  return request({
    url: "/backend/coupon/delCoupon",
    method: "post",
    data,
  });
};

// /backend/coupon/delCoupon

// 优惠券详情
export const getCouponDetail = (data) => {
  return request({
    url: "/backend/coupon/getCouponDetail",
    method: "post",
    data,
  });
};

//秒杀列表
export const seckillList = (data) => {
  return request({
    url: "/backend/seckill/seckillList",
    method: "post",
    data,
  });
};

//秒杀添加编辑
export const saveSeckill = (data) => {
  return request({
    url: "/backend/seckill/saveSeckill",
    method: "post",
    data,
  });
};

// /删除秒杀
export const delSeckill = (data) => {
  return request({
    url: "/backend/seckill/delSeckill",
    method: "post",
    data,
  });
};

// /秒杀详情
export const getSeckillDetail = (data) => {
  return request({
    url: "/backend/seckill/getSeckillDetail",
    method: "post",
    data,
  });
};

//直播抽奖列表
export const liveLotteryList = (data) => {
  return request({
    url: "/backend/livelottery/liveLotteryList",
    method: "post",
    data,
  });
};

//添加编辑直播抽奖
export const saveLivelottery = (data) => {
  return request({
    url: "/backend/livelottery/saveLivelottery",
    method: "post",
    data,
  });
};

//删除直播抽奖
export const delLivelottery = (data) => {
  return request({
    url: "/backend/livelottery/delLivelottery",
    method: "post",
    data,
  });
};

//获取直播抽奖详情
export const getLivelotteryDetail = (data) => {
  return request({
    url: "/backend/livelottery/getLivelotteryDetail",
    method: "post",
    data,
  });
};

//修改直播抽奖状态
export const updateStatus = (data) => {
  return request({
    url: "/backend/livelottery/updateStatus",
    method: "post",
    data,
  });
};

//修改用户密码
export const user_sub_pwd = (data) => {
  return request({
    url: "/backend/adminuser/user_sub_pwd",
    method: "post",
    data,
  });
};

//重置密码
export const reset_pwd = (data) => {
  return request({
    url: "/backend/adminuser/reset_pwd",
    method: "post",
    data,
  });
};

// 激活关闭门店
export const setActiveState = function(data) {
  return request({
    url: "/backend/store/setActiveState",
    method: "post",
    data,
  });
};

// 门店收支明细
export const store_capital_detail = function (data, type) {
  if(type){
    return request({
      url: '/backend/storecapitallog/list_store_capital_detail',
      method: 'post',
      responseType: type,
      headers: { "Content-Type": "form-data;charset=UTF-8" },
      data
    })
  }else{
    return request({
      url: '/backend/storecapitallog/list_store_capital_detail',
      method: 'post',
      data
    })
  }
}

// 门店收支明细详情

export const setCapitalLogDetail = function(data) {
  return request({
    url: "/backend/storecapitallog/order_detail",
    method: "post",
    data,
  });
};

// 门店收支明细列表导出

export const setCapitalLogDetailExport = function(data, type) {
  return request({
    url: '/backend/storecapitallog/order_detail',
    method: 'post',
    responseType: type,
    headers: { "Content-Type": "form-data;charset=UTF-8" },
    data
  })
};


// 店铺保证金明细
export const store_settle_in_log = function (data,type) {
  if(type){
    return request({
      url: '/backend/storesettleinlog/store_settle_in_log',
      method: 'post',
      responseType: type,
      headers: { "Content-Type": "form-data;charset=UTF-8" },
      data
    })
  }else{
    return request({
      url: '/backend/storesettleinlog/store_settle_in_log',
      method: 'post',
      data
    })
  }
}


// 获取直播商品列表
export const getLiveGoodsList = function (data) {
  return request({
    url: '/backend/live/getLiveGoodsList',
    method: 'post',
    data
  })
}

// 直播库存和直播价格修改
export const liveGoodsEdit = function (data) {
  return request({
    url: '/backend/live/liveGoodsEdit',
    method: 'post',
    data
  })
}

// 门店退出入驻
export const applyStoreSettleIn = function (data) {
  return request({
    url: '/backend/storesettleinlog/apply_store_settle_in',
    method: 'post',
    data
  })
}

// 门店退出入驻退款数量
export const storesettleinlogGoPay = function (data) {
  return request({
    url: '/backend/storesettleinlog/go_pay',
    method: 'post',
    data
  })
}

// 门店投诉列表
export const storeComplaintList = function (data) {
  // if(type){
  //   return request({
  //     url: '/backend/store/StoreComplaintList',
  //     method: 'post',
  //     responseType: type,
  //     headers: { "Content-Type": "form-data;charset=UTF-8" },
  //     data
  //   })
  // }else{
    return request({
      url: '/backend/store/StoreComplaintList',
      method: 'post',
      data
    })
  // }
}

//  投诉门店问题列表/backend/store/StoreComplaintQuestion
export const storeComplaintQuestion = function (data) {
  return request({
    url: '/backend/store/StoreComplaintQuestion',
    method: 'post',
    data
  })
}

//  投诉门店问题新增/backend/store/SaveComplaintQuestion
export const saveComplaintQuestion = function (data) {
  return request({
    url: '/backend/store/SaveComplaintQuestion',
    method: 'post',
    data
  })
}

//  投诉门店问题删除/backend/store/StatusComplaintQuestion
export const statusComplaintQuestion = function (data) {
  return request({
    url: '/backend/store/StatusComplaintQuestion',
    method: 'post',
    data
  })
}

// 处理投诉反馈信息/backend/store/StoreComplaintFeedback
export const storeComplaintFeedback = function (data) {
  return request({
    url: '/backend/store/StoreComplaintFeedback',
    method: 'post',
    data
  })
}


// 获取门店业务员列表
export const storeSalesmanList = function (data) {
  return request({
    url: '/backend/store/StoreSalesmanList',
    method: 'post',
    data
  })
}

// 获取门店业务员推荐用户列表
export const storeSalesmanUserList = function (data) {
  return request({
    url: '/backend/store/StoreSalesmanUserList',
    method: 'post',
    data
  })
}

// 获取快递模板列表
export const getExpressTemplate = function (data) {
  return request({
    url: '/backend/express/express_template',
    method: 'post',
    data
  })
}

// 编辑/保存快递模板
export const saveExpressTemplate = function (data) {
  return request({
    url: '/backend/express/save_express_template',
    method: 'post',
    data
  })
}

// 获取模板使用详情
export const useExpressTemplate = function (data) {
  return request({
    url: '/backend/express/use_express_template',
    method: 'post',
    data
  })
}

// 获取快递模板模板详情
export const getExpressTemplateDetail = function (data) {
  return request({
    url: '/backend/express/get_express_template',
    method: 'post',
    data
  })
}


// 删除门店
export const delExpressTemplate = (data) => {
  return request({
    url: "/backend/express/del_express_template",
    method: "post",
    data,
  });
};


// 重置用户
export const postUserReset = (data) => {
  return request({
    url: "/backend/storeuser/user_reset",
    method: "post",
    data,
  });
};


// 添加直播间虚拟人数
export const postSaveVirtualNumber = (data) => {
  return request({
    url: "/backend/live/save_virtual_number",
    method: "post",
    data,
  });
};


// 九宫格奖品列表
export const getLotteryList = (data) => {
  return request({
    url: "/backend/lottery/list",
    method: "get",
    params: data,
  });
};


// 九宫格设置奖品/backend/lottery/save
export const postLotterySave = (data) => {
  return request({
    url: "/backend/lottery/save",
    method: "post",
    data,
  });
};

// 修改状态 /backend/lottery/change
export const getLotteryChange = (data) => {
  return request({
    url: "/backend/lottery/change",
    method: "get",
    params: data,
  });
};

// 九宫格奖品详情 /backend/lottery/details
export const getLotteryDetails = (data) => {
  return request({
    url: "/backend/lottery/details",
    method: "get",
    params: data,
  });
};
// 九宫格选择商品列表
export const lotteryChoiceGoods = (data) => {
  return request({
    url: "/backend/lottery/choice_goods",
    method: "post",
    data,
  });
};
// // 九宫格中奖人员名单
// export const lotterywinList = (data) => {
//   return request({
//     url: "/backend/lotterywin/list",
//     method: "get",
//     params: data,
//   });
// };

// 九宫格奖品列表
export const lotterywinList = function (data,type) {
  if(type){
    return request({
      url: '/backend/lotterywin/list',
      method: 'post',
      responseType: type,
      headers: { "Content-Type": "form-data;charset=UTF-8" },
      data
    })
  }else{
    return request({
      url: '/backend/lotterywin/list',
      method: 'post',
      data
    })
  }
}
// 秒杀活动添加/backend/goodsactiveseckill/list
export const goodsactiveseckillList = (data) => {
  return request({
    url: "/backend/goodsactiveseckill/list",
    method: "get",
    params: data,
  });
};

//  新建编辑提交活动/backend/goodsactiveseckill/save
export const goodsactiveseckillSave = (data) => {
  return request({
    url: "/backend/goodsactiveseckill/save",
    method: "post",
    data,
  });
};

// 秒杀活动删除 /backend/goodsactiveseckill/delete
export const goodsactiveseckillDelete = (data) => {
  return request({
    url: "/backend/goodsactiveseckill/delete",
    method: "get",
    params: data,
  });
};
// 秒杀活动详情 /backend/goodsactiveseckill/details
export const goodsactiveseckillDetails = (data) => {
  return request({
    url: "/backend/goodsactiveseckill/details",
    method: "get",
    params: data,
  });
};
// 秒杀商品列表 /backend/goodsseckill/list
export const goodsseckillList = (data) => {
  return request({
    url: "/backend/goodsseckill/list",
    method: "get",
    params: data,
  });
};
// 添加秒杀商品/backend/goodsseckill/save
export const goodsseckillSave = (data) => {
  return request({
    url: "/backend/goodsseckill/save",
    method: "post",
    data,
  });
};
// 秒杀商品编辑 /backend/goodsseckill/details
export const goodsseckillDetails = (data) => {
  return request({
    url: "/backend/goodsseckill/details",
    method: "get",
    params: data,
  });
};
// 秒杀商品删除  /backend/goodsseckill/delete
export const goodsseckillDelete = (data) => {
  return request({
    url: "/backend/goodsseckill/delete",
    method: "post",
    data,
  });
};
// 秒杀商品修改状态/backend/goodsactiveseckill/change
export const goodsseckillChange = (data) => {
  return request({
    url: "/backend/goodsactiveseckill/change",
    method: "get",
    params: data,
  });
};
// 秒杀选择商品列表
export const goodsactiveseckillChoiceGoods = (data) => {
  return request({
    url: "/backend/goodsactiveseckill/choice_goods",
    method: "post",
    data,
  });
};

// 添加商品选择绑定商品列表
export const goodsChoiceGoods = (data) => {
  return request({
    url: "/backend/goods/choice_goods",
    method: "post",
    data,
  });
};
// 直播邀约排行
export const postLiveInvite = (data) => {
  return request({
    url: "/backend/live/live_invite",
    method: "post",
    data,
  });
};

// 直播邀约排行
export const postLiveGoodsSale = (data) => {
  return request({
    url: "/backend/live/live_goods_sale",
    method: "post",
    data,
  });
};

// 直播邀约排行详情
export const postLiveInviteDetail = (data) => {
  return request({
    url: "/backend/live/live_invite_detail",
    method: "post",
    data,
  });
};